<template>
  <div class="external-transaction-activity">
    <!-- <PageTitle title="扣款紀錄" btn-plain btn="查看扣款紀錄編輯紀錄" @btnClick="openPaymentRecord" /> -->
    <PageTitle title="扣款紀錄" hideBtn @btnClick="openPaymentRecord" />
    <!-- <FiltersContainer> -->
    <div class="flex mb-[20px] gap-[12px] flex-col">
      <el-checkbox-group
        v-model="search.hideOldRecord"
        @change="refresh(true)"
      >
        <el-checkbox :label="true">隱藏「當期」以前的扣款紀錄</el-checkbox>
      </el-checkbox-group>
      <el-select
        v-model="search.status"
        class="w-[400px]"
        clearable
        placeholder="扣款狀態"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="status in subscribeOrderPaymentStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </div>
    <!-- </FiltersContainer> -->
    <SubscribePaymentRecordTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" @handleClickItem="handleClickItem" />
    <EditSubscribePaymentRecordDialog
      v-if="SubscribePaymentRecordDialog"
      :selectItem="selectItem"
      :type="subscribePaymentRecordDialogType"
      :failRecord="failRecord"
      @refresh="refreshOrder"
      @openRecord="modal.fail = true"
      @close="SubscribePaymentRecordDialog = false"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <SubscribePaymentRecordEditRecordDetail
      :show="modal.detail"
      @close="modal.detail = false"
      @cancel="modal.detail = false"
    />
    <SubscribePaymentFailRecord
      :show="modal.fail"
      @close="modal.fail = false"
      @cancel="modal.fail = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import { get, isBoolean } from 'lodash'
// import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import EditSubscribePaymentRecordDialog from './EditSubscribePaymentRecordDialog.vue'
import SubscribePaymentRecordTable from './SubscribePaymentRecordTable.vue'
import { subscribeOrderPaymentStatusConfig } from '@/config/subscribe'
// import { GetRewardActivity, GetRewardActivityCount } from '@/api/rewardActivity'
import { GetSubscribeOrderPayment, GetSubscribeOrderPaymentCount } from '@/api/subscribe'
import SubscribePaymentRecordEditRecordDetail from './SubscribePaymentRecordEditRecordDetail.vue'
import SubscribePaymentFailRecord from './SubscribePaymentFailRecord.vue'

export default defineComponent({
  name: 'SubscribePaymentRecordBlock',
  components: {
    PageTitle,
    // FiltersContainer,
    SubscribePaymentRecordTable,
    SubscribePaymentRecordEditRecordDetail,
    EditSubscribePaymentRecordDialog,
    SubscribePaymentFailRecord,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      // name: null,
      // content: null,
      hideOldRecord: false,
      status: null,
    })
    const failRecord = computed(() => get(props, 'orderData.failRecord'))
    const modal = reactive({
      detail: false,
      fail: false,
    })
    const SubscribePaymentRecordDialog = ref(false)
    const subscribePaymentRecordDialogType = ref('create')
    const selectItem = ref(null)
    const handleClickItem = (type, row) => {
      console.log('handleClickItem', type, row)
      // if (type === 'delete') {
      //   selectItem.value = row
      //   deleteDialog.value = true
      // }
      if (type === 'create') {
        selectItem.value = null
        SubscribePaymentRecordDialog.value = true
      }
      if (type === 'edit') {
        selectItem.value = row
        SubscribePaymentRecordDialog.value = true
      }
      if (type === 'view') {
        selectItem.value = row
        SubscribePaymentRecordDialog.value = true
      }
      subscribePaymentRecordDialogType.value = type
    }
    const openPaymentRecord = () => {
      modal.detail = true
    }
    const onCreate = () => {
      // router.push({ name: 'SubscribeCombineEdit' })
      SubscribePaymentRecordDialog.value = true
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        orderId: route.params.id,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortBy: 'periodNumber',
        sortType: 'ASC',
        status: search.status || undefined,
        hideOldRecord: isBoolean(search.hideOldRecord) ? search.hideOldRecord : undefined,
      }
      await Promise.allSettled([
        fetchData(GetSubscribeOrderPayment, payload),
        fetchDataCount(GetSubscribeOrderPaymentCount, payload),
      ])
    }
    const refreshOrder = async () => {
      await refresh()
      emit('refresh')
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    // onActivated(() => {
    //   refresh()
    // })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      onCreate,
      SubscribePaymentRecordDialog,
      subscribePaymentRecordDialogType,
      selectItem,
      handleClickItem,
      openPaymentRecord,
      modal,
      subscribeOrderPaymentStatusConfig,
      failRecord,
      refreshOrder,
    }
  },
})
</script>
