<template>
  <div class="external-transaction-activity-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="期數" prop="periodNumber" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.periodNumber || '-' }} <br>{{ scope.row.isCurrent ? '(當期)': '' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="期數起始日-結束日" prop="timeRange" align="center" />
      <el-table-column label="扣款紀錄編號" prop="paymentNumber" align="center" />
      <el-table-column label="原訂扣款日" prop="expectedOrderPaymentDate" align="center" />
      <el-table-column label="實際扣款日" prop="paymentDate" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.paymentDate || '-' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="扣款金額" prop="paymentAmount" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.paymentAmount || '-' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="退款金額" prop="refundAmount" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.refundAmount || '-' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="退款日" prop="refundDate" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.refundDate || '-' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="扣款狀態" prop="paymentStatus" align="center" fixed="right">
        <template slot-scope="scope">
          <Tag :type="paymentStatus(scope.row.status, 'tagType')">{{ paymentStatus(scope.row.status, 'label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="140">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :hideDelete="true"
            @edit="onEdit(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import { defineComponent, computed, reactive } from 'vue'
import dayjs from '@/lib/dayjs'
import { get, map } from 'lodash'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { formatDate } from '@/utils/date'
import { subscribeOrderPaymentStatusConfig } from '@/config/subscribe'

export default defineComponent({
  name: 'SubscribeCombineItemTable',
  components: {
    EmptyBlock,
    Tag,
  },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const modal = reactive({
      delete: false,
    })

    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const {
          id,
          periodNumber,
          price,
          status,
          refundDate,
          refundAmount,
          paymentAmount,
          paymentDate,
          expectedOrderPaymentDate,
          paymentNumber,
          startDate,
          endDate,
          paymentType,
          refundType,
          note,
        } = item
        return {
          id,
          periodNumber,
          isCurrent: dayjs().isBetween(startDate, endDate, 'day', '[)'),
          price,
          status,
          refundDate: refundDate ? formatDate(refundDate, 'YYYY/MM/DD') : null,
          refundAmount: refundAmount,
          paymentAmount: paymentAmount,
          paymentDate: paymentDate ? formatDate(paymentDate, 'YYYY/MM/DD') : null,
          expectedOrderPaymentDate: expectedOrderPaymentDate ? formatDate(expectedOrderPaymentDate, 'YYYY/MM/DD') : '-',
          paymentNumber: paymentNumber,
          timeRange: `${formatDate(startDate, 'YYYY/MM/DD')} - ${formatDate(endDate, 'YYYY/MM/DD')}`,
          paymentType,
          refundType,
          note,
        }
      })
    })
    const currentPeriod = computed(() => {
      return ''
    })
    const paymentStatus = (status, attr) => {
      return get(subscribeOrderPaymentStatusConfig[status], attr)
    }

    const onEdit = (row) => {
      emit('handleClickItem', 'edit', row)
    }

    return {
      modal,
      displayData,
      get,
      dayjs,
      onEdit,
      subscribeOrderPaymentStatusConfig,
      paymentStatus,
      currentPeriod,
    }
  },
})
</script>
